<!-- 全景图页面 -->
<template>
  <div class="qjt" id="qjtID">
    <div class="picArr">
      <div class="pic pic1" @click="sphereChange('0693')"></div>
      <div class="pic pic2" @click="sphereChange('0694')"></div>
    </div>
  </div>
</template>

<script>
import PhotoSphereViewer from 'photo-sphere-viewer';
import { Viewer } from "photo-sphere-viewer";
import MarkersPlugin from "photo-sphere-viewer/dist/plugins/markers"; //Markers插件
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import "photo-sphere-viewer/dist/plugins/markers.css"; //Markers插件
export default {
  components: {

  },
  data () {
    return {
      title: "全景图",
      viewer: null,
      sphereUrl: 'image/DJI_0693.JPG'
    };
  },
  mounted () {

    console.log(PhotoSphereViewer, 'PhotoSphereViewer');

    this.init();
  },
  created () {
    document.title = this.title;
  },
  methods: {
    init () {
      this.viewer = new Viewer({
        container: document.querySelector("#qjtID"),
        panorama: this.sphereUrl,
        // left: 'image/PANO0001.JPG', front: 'image/PANO0002.JPG',
        // right: 'image/PANO0003.JPG', back: 'image/PANO0004.JPG',
        // top: 'image/PANO0005.JPG', bottom: 'image / PANO0006.JPG',

        size: {
          width: "100vw",
          height: "100vh",
        },
        autorotateLat: 0,//
        autorotateSpeed: 0.20943951023931962,//自传速度
        defaultZoomLvl: 0,//默认缩放
        defaultLong: 0.0027446609001040845,//经度
        defaultLat: 0.015220228499811306,//维度
        autorotateDelay: 1,//1毫秒后启动自动旋转
      });
    },
    sphereChange (flag) {
      if (flag == '0693') {
        this.sphereUrl = 'image/DJI_0693.JPG';
        // this.sphereUrl = 'image/pano3.jpg';
      } else {
        this.sphereUrl = 'image/DJI_0694.JPG';
      }
      this.viewer.destroy();
      this.init();
    }
  },
};
</script>

<style lang="scss" scoped>
.qjt {
  width: 100%;
  height: 100%;
  // display: flex;
  // align-items: flex-end;

  // background-color: yellow;
  .picArr {
    position: absolute;
    margin: 0 auto;
    bottom: 50px;
    right: calc(50% - 150px);
    width: 300px;
    height: 85px;
    // background-color: yellow;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .pic {
      width: 45%;
      height: 100%;
      box-sizing: border-box;
      // border: 1px solid rgb(255, 0, 21);
      cursor: pointer;
    }

    .pic1 {
      background-image: url('~@/assets/img/0693.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .pic2 {
      background-image: url('~@/assets/img/0694.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .pic1:hover {
      scale: 1.2;
    }

    .pic2:hover {
      scale: 1.2;
    }
  }
}
</style>
